<template>
  <v-dialog v-if="dialog" v-model="dialog" width="800" height="30vh">
    <v-card min-height="30vh">
      <v-card-text>
        <v-form ref="forgotPasswordForm" v-model="form" lazy-validation>
          <v-card-title>{{ addon.name }} Sales</v-card-title>
          <v-data-table
            :headers="headers"
            item-key="_id"
            :items="salesData"
            hide-default-footer
            :expanded.sync="expanded"
            show-expand
            :single-expand="true"
          >
            <template v-slot:expanded-item="{ headers, item }">
              <td :colspan="headers.length">
                <v-card>
                  <v-card-title>Attribute</v-card-title>
                  <v-data-table :headers="addHeaders" :items="item.attributes" hide-default-footer>
                    <template v-slot:[`item.addon`]="{ item }">
                      <span>{{ item.addonName }}</span>
                    </template>
                  </v-data-table>
                </v-card>
              </td>
            </template>
          </v-data-table>

          <v-spacer />
          <v-card-actions>
            <div class="mx-auto">
              <v-btn class="mx-1" width="100" @click="onCancel()">Cancel</v-btn>
            </div>
          </v-card-actions>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { AddonService } from '@services';
export default {
  props: {
    addon: { type: Object, required: true },
  },
  data() {
    return {
      expanded: [],
      dialog: true,
      loading: false,
      headers: [
        { text: 'Event Name', value: 'title', sortable: false, width: '150px' },
        { text: 'Quantity', value: 'quantity', sortable: false, width: '20px' },
        { text: 'Purchase Quantity', value: 'purchaseQuantity', sortable: false, width: '100px' },
      ],
      addHeaders: [
        { text: 'Attribute Name', value: 'value', sortable: false, width: '150px' },
        { text: 'Quantity', value: 'quantity', sortable: false, width: '20px' },
        { text: 'Purchase Quantity', value: 'purchaseQuantity', sortable: false, width: '100px' },
      ],
      salesData: [],
    };
  },
  mounted() {
    this.getAddonSales();
  },
  methods: {
    onCancel() {
      this.dialog = false;
      this.$root.$emit('close-addon-sales');
    },
    async getAddonSales() {
      this.salesData = await AddonService.getAddonSales(this.addon._id);
    },
  },
};
</script>
