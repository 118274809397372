<template>
  <v-form ref="form" v-model="valid" lazy-validation>
    <v-card class="mb-4">
      <v-card-title>
        <div>{{ text }} Add Ons</div>
      </v-card-title>
      <v-card-text class="pb-4 pb-sm-8">
        <v-row>
          <v-col cols="12" :sm=" isPromoter ? 6 : 4">
            <v-text-field
              outlined
              dense
              name="name"
              clearable
              label="Name"
              @change="onChangeName()"
              v-model="data.name"
              @input="nameError = []"
              :error-messages="nameError"
              :rules="[
                v => !!v && v && !!v.trim() || 'Name is required', 
                v => (v && v.length < 50) || 'Name must less than 50 character'
              ]"
              id="name"
              placeholder="e.g. Name of item/product"
            />
          </v-col>
          <v-col cols="12" :sm=" isPromoter ? 6 : 4">
            <v-autocomplete
              v-model="data.supplier"
              :items="suppliers"
              item-text="name"
              clearable
              item-value="_id"
              @change="onChangeSupplier()"
              label="Supplier"
              :rules="[v => !!v || 'Supplier is required']"
              dense
              outlined
            ></v-autocomplete>
          </v-col>
          <v-col cols="12" sm="4" v-if="!isPromoter">
            <v-autocomplete
              v-model="data.organiser"
              :items="organisers"
              :filter="customFilter"
              item-text="name"
              multiple
              clearable
              item-value="_id"
              label="Promoter"
              :rules="[v => !!v || 'Promoter is required']"
              dense
              outlined
            >
              <template v-slot:selection="data">
              <span>{{ data.item.name }} ( {{ data.item.type }} )</span>
              </template>
              <template v-slot:item="{item, attrs, on}">
                <v-list-item v-on="on" v-bind="attrs" #default="{ active }">
                  <v-list-item-action>
                    <v-checkbox :ripple="false" :input-value="active"></v-checkbox>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title>
                      <v-row no-gutters align="center">
                        {{ item.name }} 
                      </v-row>
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </template>
            </v-autocomplete>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="4">
            <v-autocomplete
              v-model="data.addonType"
              :items="addonTypes"
              item-text="name"
              clearable
              item-value="_id"
              label="Type"
              :rules="[v => !!v || 'Addon Type is required']"
              dense
              outlined
            ></v-autocomplete>
          </v-col>
          <v-col cols="12" sm="2" v-if="isAdmin">
            <v-btn outlined color="primary" @click="addSupplierType()">Add new type</v-btn>
          </v-col>
          <v-col cols="12" sm="3">
            <v-text-field
              outlined
              dense
              name="quantity"
              @input="quantityError = [], onQuantityChangeCount()"
              :error-messages="quantityError"
              :maxlength="5"
              v-on:keypress="isNumber($event)"
              disabled
              clearable
              label="Quantity Max"
              :value="totalQuantity"
              :rules="[
                v => !!v || 'Quantity is required',
                v =>  v && v >= 1 || 'Quantity is required'
              ]"
              id="quantity"
              placeholder="e.g. 200"
            />
          </v-col>
          <v-col cols="12" sm="1" class="mt-2">
            Active
          </v-col>
          <v-col class="mt-2">
            <v-switch class="pa-0 ma-0" v-model="data.status1">
              <template v-slot:label>
                {{data.status1 ? 'On' : 'Off'}}
              </template>
            </v-switch>
          </v-col>
          <v-col cols="12" :sm="isAdmin ? 4 : 4">
            <v-text-field
              outlined
              dense
              name="valueName"
              clearable
              label="Value Name"
              v-model="data.valueName"
              id="valueName"
              placeholder="e.g. Size"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="10" class="primary--text text-bold"> Add Ons Attributes </v-col>
          <v-col cols="2">
            <v-btn color="primary" width="150" @click="addAttribute()"> Add Attribute </v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-row v-for="(attr, index) in data.attributes" :key="index">
              <v-col cols="12" sm="3">
                <v-text-field
                  outlined
                  dense
                  name="value"
                  clearable
                  label="Item"
                  v-model="attr.value"
                  :rules="[v => !!v && v && !!v.trim() || 'Item is required']"
                  id="value"
                  placeholder="e.g. S, M, L, XL"
                />
              </v-col>
              <v-col cols="12" sm="3">
                <v-text-field
                  outlined
                  dense
                  name="price"
                  :disabled="attr.isFree"
                  clearable
                  label="Price"
                  v-model="attr.price"
                  :maxlength="7"
                  min="0"
                  :rules="[
                    v => checkPriceNew(attr.price, attr.isFree) || 'Price is required',
                    v => desimalNumberFormat(attr.price) || 'Only two digits after decimal points allowed.',
                  ]"
                  id="price"
                  placeholder="e.g. 20"
                />
              </v-col>
              <v-col cols="12" sm="3">
                <v-text-field
                  outlined
                  dense
                  name="quantity"
                  clearable
                  label="Quantity"
                  v-model="attr.quantity"
                  v-on:keypress="isNumber($event)"
                  @change="calculateQuantity()"
                  :maxlength="7"
                  min="0"
                  :rules="[checkAddonQuantity(attr.quantity) || 'Quantity is required', onQuantityChangeCount(attr)]"
                  id="price"
                  placeholder="e.g. 20"
                />
              </v-col>
              <v-col cols="12" sm="1" class="mt-2">
                <v-switch class="pa-0 ma-0" :label="`Free`" v-model="attr.isFree" @change="freeChangeNew(index)"></v-switch>
              </v-col>
              <v-col cols="12" sm="1">
                <v-icon class="cursor-pointer pt-2" color="primary" @click="removeAttribute(index)"
                  >mdi-delete-outline</v-icon
                >
              </v-col>
            </v-row>
          </v-col>
          
        </v-row>
        <v-row>
          <v-col cols="12" sm="12">
            <quill-editor v-model="data.description"> </quill-editor>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="12">
            <v-card outlined>
              <v-card-title class="primary--text"
                >Upload Image<span
                  style="font-size: 0.875rem;
                      font-weight: 400;
                      line-height: 1.375rem;
                      letter-spacing: 0.0071428571em;
                      margin-left: 5px;
                      color: rgba(0, 0, 0, 0.6);"
                >
                  (optional)</span
                ></v-card-title
              >

              <v-card-text>
                <v-img
                  :aspect-ratio="$vuetify.breakpoint.xs ? 16 / 9 : 10 / 4"
                  class="v-sheet--outlined"
                  v-if="url"
                  :src="url"
                >
                  <v-btn class="float-right" color="error" fab icon @click="removeImage">
                    <v-icon>mdi-delete-outline</v-icon>
                  </v-btn>
                </v-img>

                <v-img
                  :aspect-ratio="$vuetify.breakpoint.xs ? 16 / 9 : 10 / 4"
                  class="v-sheet--outlined"
                  v-else-if="data.image"
                  :src="$getAddonImage(data._id, data.image)"
                >
                  <v-btn class="float-right" color="error" fab icon @click="removeImage">
                    <v-icon>mdi-delete-outline</v-icon>
                  </v-btn>

                  <template v-slot:placeholder>
                    <v-progress-circular class="center" indeterminate color="primary"></v-progress-circular>
                  </template>
                </v-img>

                <v-responsive v-else :aspect-ratio="$vuetify.breakpoint.xs ? 16 / 9 : 4">
                  <div
                    class="border-dashed h-100 cursor-pointer"
                    @click="$refs.file.click()"
                    @drop.prevent="drop"
                    @dragover.prevent
                  >
                    <div class="center">
                      <v-icon x-large>mdi-file-outline</v-icon>
                      Drag n Drop here or <span style="color: red;"> Browse</span> to add main event image.<br />
                      <span style="margin-left: 110px;">JPG, JPEG or PNG, no larger than 12MB.</span>
                    </div>
                  </div>
                </v-responsive>
              </v-card-text>
              <v-card-subtitle class="primary--text"
                >Please upload image with minimum dimensions 100x100.</v-card-subtitle
              >
              <v-card-subtitle class="primary--text mb-4" style="padding: 0px 16px"
                >For better quality upload image with dimensions 1600x900.</v-card-subtitle
              >

              <v-card-actions>
                <v-spacer />
                <v-btn class="px-16" large color="secondary" @click="$refs.file.click()">
                  {{ url || data.image ? 'Replace' : 'Upload' }} image
                </v-btn>
                <v-spacer />
              </v-card-actions>

              <input
                type="file"
                ref="file"
                class="d-none"
                multiple
                accept="image/png, image/jpeg"
                onclick="this.value = null;"
                @change="changeFile()"
              />
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn width="100" outlined color="primary" @click="discard()">Cancel</v-btn>
        <v-btn width="100" color="primary" @click="save()" :loading="loading.save">Save</v-btn>
      </v-card-actions>
    </v-card>
    <v-dialog v-model="dialog" width="400px">
      <!-- <v-overlay :value="loadingSupplierType">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay> -->
      <v-card min-height="30vh">
        <v-form ref="addonForm" v-model="addonForm" lazy-validation>
          <v-card-title class="text-bold text-h6">Addon Type</v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="12" sm="12">
                <v-text-field
                  outlined
                  dense
                  name="addonType"
                  clearable
                  label="Add Addon Type Name"
                  v-model="addonType"
                  :rules="[
                    v => !!v && v && !!v.trim() || 'Addon Type Name is required', 
                    v => (v && v.length < 20) || 'Addon Type Name must less than 20 character'
                  ]"
                  id="supplierType"
                  placeholder="Add new Addon type"
                />
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <div class="mx-auto">
              <v-spacer />
              <v-btn class="mx-1" width="100" @click="onCancel()">Cancel</v-btn>
              <v-btn class="mx-1" width="100" :loading="loading.saveAddon" color="primary" @click="saveAddon()"
                >Save</v-btn
              >
            </div>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </v-form>
</template>

<script>
import { AddonTypeService, AddonService, SupplierService, PromoterService, MediaService } from '@services';
export default {
  data() {
    return {
      rules: [value => value || value.size < Number(process.env.VUE_APP_IMAGE_SIZE) || 'Max file size is 12 mb'],
      valid: false,
      loading: {
        saveAddon: false,
        save: false,
      },
      text: 'Add',
      data: {
        status: 'active',
        attributes: [],
        status1: true,
      },
      files: [],
      organisers: [],
      suppliers: [],
      addonTypes: [],
      nameError: [],
      quantityError: [],
      dialog: false,
      addonForm: false,
      addonType: '',
      isAdmin: false,
      isImageChange: false,
      url: null,
      isPromoter: false,
      supplierQuery: {
        limit: 'all',
        status: 'active',
        status1: true,
        promoter: [],
      },
      totalQuantity: 0,
    };
  },
  async created() {
    if (this.$route.params.id) {
      this.text = 'Edit';
      this.getAddonById(this.$route.params.id);
    } else {
      this.getPromoters();
    }
    if (this.$user.role === 'admin') {
      this.isAdmin = true;
    } else if (this.$user.role === 'promoter') {
      this.isPromoter = true;
      this.supplierQuery.promoter.push(this.$user.organiser)
    }
    this.getSuppliers();
    this.getAddonTypes();
  },
  methods: {
    async getAddonById(id) {
      this.data = await AddonService.getById(id);
      console.log("🚀 ~ file: index.vue ~ line 363 ~ getAddonById ~ this.data", this.data)
      this.data.price = this.data.price / 100;
      if(this.data.attributes && this.data.attributes.length) {
        for(let i = 0; i < this.data.attributes.length; i++) {
          this.data.attributes[i].price = this.data.attributes[i].price / 100;
          this.data.attributes[i].price = this.data.attributes[i].price.toFixed(2)
        }
      }
      if(this.data.status === 'inactive') {
        this.data.status1 = false;
      }
      if(this.data.status === 'active') {
        this.data.status1 = true;
      }
      this.calculateQuantity();
      this.getPromoters();
    },
    customFilter (item, queryText) {
      const textOne = `${item.name.toLowerCase()} - ${item.type.toLowerCase()}`
      const searchText = queryText.toLowerCase()
      return textOne.indexOf(searchText) > -1 
    },
    async getPromoters() {
      const organiserData = await PromoterService.get({ limit: 'all', supplier: this.data.supplier, type: 'promoter' });
      this.organisers = organiserData.organisers;
    },
    async getSuppliers() {
      const suppliersData = await SupplierService.getSuppliers(this.supplierQuery);
      this.suppliers = suppliersData.supplier;
    },
    async getAddonTypes() {
      const addonTypeData = await AddonTypeService.get({ limit: 'all' });
      this.addonTypes = addonTypeData.addonTypes;
    },
    async save() {
      if(!this.data.attributes.length) {
        this.$error('Please add at lease one product attribute.');
        return false;
      }
      if (this.$refs.form.validate() && !this.nameError.length && !this.quantityError.length) {
        this.$confirm({
          title: `Are you sure you want to ${this.data._id ? 'update' : 'create'} this Addon?`,
          resolve: async () => {
            this.loading.save = true;
            if(!this.data.status1) {
              this.data.status = 'inactive';
            } else if(this.data.status1) {
              this.data.status = 'active';
            }
            if (this.data._id) {
              const response = await AddonService.update(this.data);
              if (response) {
                this.uploadAddonimage(response);
              } else {
                this.loading.save = false;
              }
            } else {
              if (this.$user.role === 'promoter') {
                this.data.organiser = [];
                this.data.organiser.push(this.$user.organiser);
              }
              const response = await AddonService.save(this.data);
              if (response) {
                this.data._id = response.id;
                this.uploadAddonimage(response);
              } else {
                this.loading.save = false;
              }
            }
          },
        });
      }
    },
    async uploadAddonimage() {
      if (this.isImageChange) {
        const key = Date.now();
        await MediaService.uploadAddonimage(key, { file: this.files }, this.data._id);
      }
      this.loading.save = false;
      if (this.text == 'Edit') {
        this.$success('Item Update successfully');
      } else {
        this.$success('Item Saved successfully');
      }
      if (this.isAdmin) {
        this.$next({ name: 'admin.add-ons' });
      } else {
        this.$next({ name: 'promoter.add-ons' });
      }
    },
    discard() {
      if (this.isAdmin) {
        this.$next({ name: 'admin.add-ons' });
      } else {
        this.$next({ name: 'promoter.add-ons' });
      }
    },
    addSupplierType() {
      this.dialog = true;
    },
    async saveAddon() {
      if (this.$refs.addonForm.validate() && !this.nameError.length && !this.quantityError.length) {
        this.loading.saveAddon = true;
        const data = await AddonTypeService.save({ name: this.addonType });
        if (data) {
          this.addonType = '';
          this.dialog = false;
          await this.getAddonTypes();
          this.data.addonType = data._id || '';
        }
        this.loading.saveAddon = false;
      }
    },
    onCancel() {
      this.dialog = false;
    },
    freeChange() {
      if (this.data.isFree) {
        this.data.price = 0;
      }
    },
    checkPrice() {
      this.data.price = (this.data.price).toString();
      return !this.data.isFree && !this.data.price ? false : true;
    },
    desimalNumberFormat(value) {
      if (value) {
        console.log("🚀 ~ file: index.vue ~ line 415 ~ desimalNumberFormat ~ value", value)
        value = value.toString();
        value = value.split('.');
        if(value.length > 2) {
          return false;
        }
        return value.length === 2 && value[1].length > 2 ? false : true;
      }
      return true;
    },
    async onChangeName() {
      const response = await AddonService.checkDuplicate(this.data.name);
      console.log("🚀 ~ file: index.vue ~ line 489 ~ onChangeName ~ this.data.name", this.data.name)
      if (response && response._id != this.data._id) {
        this.nameError = ['Name already exists'];
      }
    },
    changeFile() {
      if (this.$refs.file.files[0].size > Number(process.env.VUE_APP_IMAGE_SIZE)) {
        this.$error('Max file size is 12 mb');
        return;
      } else {
        let img = new Image();
        img.src = window.URL.createObjectURL(this.$refs.file.files[0]);
        this.url = img.src;
        img.onload = () => {
          if (
            img.width >= Number(process.env.VUE_APP_IMAGE_WIDTH) &&
            img.height >= Number(process.env.VUE_APP_IMAGE_HEIGHT)
          ) {
            // this.url = window.URL.createObjectURL(this.files);
            this.files = this.$refs.file.files[0];
            console.log("🚀 ~ file: index.vue ~ line 432 ~ changeFile ~ this.files", this.files)
            this.isImageChange = true;
          } else {
            this.$error('Please use an image 100 x 100 or above.');
          }
        };
      }
    },

    drop(event) {
      if (event.target.files[0].size > Number(process.env.VUE_APP_IMAGE_SIZE)) {
        this.$error('Max file size is 12 mb');
        return;
      } else {
        let img = new Image();
        img.src = window.URL.createObjectURL(event.target.files[0]);
        this.url = img.src;
        img.onload = () => {
          if (
            img.width >= Number(process.env.VUE_APP_IMAGE_WIDTH) &&
            img.height >= Number(process.env.VUE_APP_IMAGE_HEIGHT)
          ) {
            // this.addFiles(event.target.files);
            this.files = event.dataTransfer.files[0];
            // this.url = window.URL.createObjectURL(this.files);
            this.isImageChange = true;
          } else {
            this.$error('Please use an image 100 x 100 or above.');
          }
        };
      }
    },

    removeImage() {
      this.$confirm({
        title: `Are you sure you want to delete this Image?`,
        resolve: async () => {
          this.url = null;
          this.data.image = null;
          this.isImageChange = false;
        },
      });
    },
    onChangeSupplier() {
      this.data.organiser = [];
      this.getPromoters();
    },
    onQuantityChangeCount(attr) {
      let quantity = 0;
      if(this.data && this.data.events && this.data.events.length) {
        for (let index = 0; index < this.data.events.length; index++) {
          const element = this.data.events[index];
          for (let aindex = 0; aindex < element.addson.length; aindex++) {
            const addon = element.addson[aindex];
            if (addon.addon === this.$route.params.id) {
              const attribute = addon.attributes.find((a) => a.id === attr.id);
              if (attribute) {
                quantity = quantity + attribute.quantity;
              }
            }
          } 
        }
      }
      if (quantity > attr.quantity) {
        return `Minimun Quantity Allow ${quantity}`
      }
      return true;
    },
    addAttribute() {
      this.data.attributes.push({ value: '', isFree: false, quantity: '', id: this.$uuid.v4() });
    },
    removeAttribute(index) {
      this.data.attributes.splice(index, 1);
      this.calculateQuantity();
    },
    checkPriceNew(price, isFree) {
      if(price === undefined || price === '') {
        return false;
      }
      return !isFree && price < 0 ? false : true;
    },
    freeChangeNew(index) {
      let isFree = this.data.attributes[index].isFree;
      if (isFree) {
        this.data.attributes[index].price = 0;
      }
    },
    calculateQuantity() {
      let totalQuantity = 0;
      if(this.data && this.data.attributes) {
        this.data.attributes.map((attr) => {
          totalQuantity = totalQuantity + (+attr.quantity);
        })
      }
      this.totalQuantity = totalQuantity;
      return totalQuantity;
    },
    checkAddonQuantity(qty) {
      qty = qty.toString()
      if(!qty)
        return false;
      else 
        return true;
    }
  },
};
</script>
<style scoped>
.border-dashed {
  border: 2px dashed;
}
</style>