<template>
  <div>
    <v-card class="mb-4">
      <v-card-title>
        <div>Filters</div>
        <v-spacer />
        <v-btn icon small>
          <v-icon small color="primary" @click="resetFilter()">mdi-reload</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text class="pb-4 pb-sm-8">
        <v-row>
          <v-col cols="12" sm="3">
             <v-text-field
              outlined
              dense
              hide-details
              clearable
              name="name"
              label="Name"
              v-model="query.name"
              placeholder="e.g. Name"
            />
          </v-col>

          <!--v-col cols="12" sm="4">
            <v-text-field
              :class="{ 'v-input-has-value ': query.price }"
              v-on:keypress="isNumber($event)"
              outlined
              dense
              hide-details
              clearable
              name="price"
              label="Price"
              v-model="query.price"
              id="price"
              placeholder="e.g. 25"
            />
          </v-col>

          <v-col cols="12" sm="4">
            <v-text-field
              :class="{ 'v-input-has-value ': query.quantity }"
              v-on:keypress="isNumber($event)"
              outlined
              dense
              hide-details
              clearable
              name="quantity"
              label="Quantity"
              v-model="query.quantity"
              id="quantity"
              placeholder="e.g. 25"
            />
          </v-col-->
          <v-col cols="12" sm="3">
            <v-autocomplete
              :class="{ 'v-input-has-value ': query.supplier }"
              v-model="query.supplier"
              :items="supplieres"
              :item-text="'name'"
              :item-value="'_id'"
              label="Supplier"
              dense
              clearable
              @click:clear="clear('supplier')"
              outlined
            ></v-autocomplete>
          </v-col>
          <v-col cols="12" sm="3">
            <v-autocomplete
              :class="{ 'v-input-has-value ': query.type }"
              v-model="query.type"
              :items="types"
              :item-text="'name'"
              :item-value="'_id'"
              label="Type"
              dense
              clearable
              @click:clear="clear('type')"
              outlined
            ></v-autocomplete>
          </v-col>
          <v-col cols="12" sm="3">
            <v-select
              v-model="query.status"
              :items="statuses"
              :item-text="'text'"
              :item-value="'value'"
              label="Status"
              dense
              clearable
              @change="statusChange()"
              @click:clear="clear('status')"
              outlined
            ></v-select>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-card>
      <v-card-title class="d-flex">
        <v-row>
          <v-spacer />
          <v-col cols="12" sm="4" xl="3" class="text-left text-sm-right">
            <v-row>
              <v-col cols="6">
                <v-btn block outlined color="primary" :loading="loading.export" @click="exportExcel()">Export</v-btn>
              </v-col>
              <v-col cols="6">
                <v-btn block color="primary" @click="changeRoute()">Add new</v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card-title>

      <v-data-table :headers="headers" :items="desserts" hide-default-footer>
        <template v-slot:[`item.status`]="{ item }">
          <span>{{ item.status | capitalize }}</span>
        </template>
        <template v-slot:[`item.addonType`]="{ item }">
          <span>{{ item.addonType ? item.addonType.name : 'N/A' }} </span>
        </template>
        <template v-slot:[`item.price`]="{ item }">
          <span>{{ item.price | currency }} </span>
        </template>
        <template v-slot:[`item.quantity`]="{ item }">
          <span v-if="item && item.attributes && item.attributes.length">
            {{ showQuantity(item.attributes) }}
          </span>
          <span v-else>0</span>
        </template>
        <template v-slot:[`item.supplier`]="{ item }">
          {{ item.supplier.name }} 
        </template>
        <template v-slot:[`item.soldQuantity`]="{ item }">
          <a @click="showEvent(item)">{{ item.soldQuantity }}</a> 
        </template>
        <template v-slot:[`item.promoter`]="{ item }">
          <a @click="showPromoters(item.organiser)">{{ item.organiser.length }} Promoters</a>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon color="primary" v-bind="attrs" v-on="on" title="Edit" class="mr-1" @click="editItem(item)">
                mdi-pencil-outline
              </v-icon>
            </template>
            <span>Edit</span>
          </v-tooltip>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon color="primary" v-bind="attrs" v-on="on" title="Delete" class="mr-1" @click="deleteItem(item)">
                mdi-delete-outline
              </v-icon>
            </template>
            <span>Delete</span>
          </v-tooltip>
        </template>
        <template v-slot:footer>
          <v-pagination
            v-if="totalPage"
            class="text-right text-body-2 py-2 "
            circle
            :total-visible="5"
            color="primary"
            v-model="query.page"
            :length="totalPage"
          ></v-pagination>
        </template>
      </v-data-table>
    </v-card>
    <v-dialog v-if="dialog" v-model="dialog" width="500" height="30vh">
    <v-card min-height="30vh">
      <v-card-text>
        <v-card-title>{{ title }}</v-card-title>
        <v-data-table
          :headers="listHeaders"
          item-key="_id"
          :items="list"
        >
        </v-data-table>
        <v-spacer />
        <v-card-actions>
          <div class="mx-auto">
            <v-btn class="mx-1" width="100" @click="onCancel()">Cancel</v-btn>
          </div>
        </v-card-actions>
      </v-card-text>
    </v-card>
  </v-dialog>
  <AddonSales v-if="showAddonSales" :addon="selectedAddon" />
    <!-- <ListDailog v-if="dialog" :name="title" :list="list" /> -->
  </div>
</template>

<script>
import moment from 'moment';
import { AddonService, SupplierService, AddonTypeService } from '@services';
import AddonSales from './addon-sales.vue';
export default {
  components: {
    AddonSales,
  },
  data() {
    return {
      query: {
        name: '',
        page: 1,
        price: '',
        quantity: '',
        supplier: '',
        type: '',
        promoter: '',
      },
      selectedAddon: {},
      dialog: false,
      showAddonSales: false,
      totalPage: '',
      list : [],
      title: '',
      listHeaders: [
        { text: 'Name', value: 'name', sortable: false, width: '150px' },
      ],
      headers: [
        { text: 'Actions', value: 'actions', align: 'start', sortable: false, width: '100px' },
        {
          text: 'Item ID',
          sortable: false,
          value: '_id',
          width: '200px',
        },
        {
          text: 'Item',
          sortable: false,
          value: 'name',
          width: '200px',
        },
        {
          text: 'Suppliers',
          sortable: false,
          value: 'supplier',
          width: '200px',
        },
        {
          text: 'Type',
          sortable: false,
          value: 'addonType',
          width: '200px',
        },
        {
          text: 'Quantity',
          sortable: false,
          value: 'quantity',
          width: '200px',
        },
        {
          text: 'Sold Quantity',
          sortable: false,
          value: 'soldQuantity',
          width: '200px',
        },
        // {
        //   text: 'Price',
        //   sortable: false,
        //   value: 'price',
        //   width: '200px',
        // },
        {
          text: 'Status',
          sortable: false,
          value: 'status',
          width: '80px',
        }
      ],
      desserts: [],
      types: [],
      supplieres: [],
      isAdmin: false,
      isPromoter: false,
      loading: {
        export: false,
      },
      statuses: [
        { text: 'All', value: 'all' },
        { text: 'Active', value: 'active' },
        { text: 'Inactive', value: 'inactive' },
      ]
    };
  },
  mounted() {
    this.$root.$on('close-addon-sales', () => {
        this.showAddonSales = false;
    });
  },
  created() {
    Object.assign(this.query, this.$getState());
    if (this.$user.role === 'admin') {
      this.isAdmin = true;
      this.headers.splice(5, 0, {
          text: 'Promoter',
          sortable: false,
          value: 'promoter',
          width: '200px',
        });

    } else if (this.$user.role === 'promoter') {
      this.query.promoter = this.$user.organiser;
      this.isPromoter = true;
    }
    this.get();
    this.getSuppliers();
    this.getAddonTypes();
  },
  methods: {
    async get() {
      this.$setState(this.query);
      const data = await AddonService.get(this.query);
      if (data) {
        this.desserts = data.addon;
        this.calculateSoldQuantity();
        this.totalPage = Number(parseInt((data.totalCount + 10 - 1) / 10));
      }
    },
    calculateSoldQuantity() {
      for (let index = 0; index < this.desserts.length; index++) {
        const element = this.desserts[index];
        const soldQuantity = 0;
        for (let eindex = 0; eindex < element.events.length; eindex++) {
          const event = element.events[eindex];
          for (let aindex = 0; aindex < event.addson.length; aindex++) {
            const addon = event.addson[aindex];
            if (addon.addon === element._id) {
              for (let attributeIndex = 0; attributeIndex < addon.attributes.length; attributeIndex++) {
                const element = addon.attributes[attributeIndex];
                soldQuantity = soldQuantity + element.purchaseQuantity;
              }
            }
          }
        }
        this.desserts[index].soldQuantity = soldQuantity;
      }
    },
    async getSuppliers() {
      const suppliersData = await SupplierService.getSuppliers({ limit: 'all', status: 'active', });
      this.supplieres = suppliersData.supplier;
    },
    async getAddonTypes() {
      const addonTypeData = await AddonTypeService.get({ limit: 'all' });
      this.types = addonTypeData.addonTypes;
    },
    resetFilter() {
      this.query.name = '';
      this.query.price = '';
      this.query.quantity = '';
      this.query.supplier = '';
      this.query.type = '';
      this.query.page = 1;
      this.get();
    },
     clear(field) {
      this.query[field] = '';
      this.get();
    },
    onCancel() {
      this.dialog = false;
    },
    changeRoute() {
      if (this.isAdmin) {
        this.$next({ name: 'admin.add-ons.add' });
      } else {
        this.$next({ name: 'promoter.add-ons.add' });
      }
    },
    async exportExcel() {
      this.loading.export = true;
      const exportdata = await AddonService.exportExcel(this.query);
      const url = URL.createObjectURL(new Blob([exportdata]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `Addons-${moment().format('DD/MM/YYYY')}.xlsx`);
      document.body.appendChild(link);
      link.click();
      this.loading.export = false;
    },
    editItem(item) {
      if (this.isAdmin) {
        this.$next({ name: 'admin.add-ons.edit', params: { id: item._id } });
      } else {
        this.$next({ name: 'promoter.add-ons.edit', params: { id: item._id } });
      }
    },
    async deleteItem(item) {
      this.$confirm({
        title: `Are you sure you want to delete this Addon?`,
        resolve: async () => {
          await AddonService.delete(item._id);
          this.get();
        },
      });
    },
    showPromoters(item) {
      this.dialog = false;
      this.list = item;
      this.title = 'Promoters'
      this.dialog = true
    },
    statusChange() {
      this.get();
    },
    showQuantity(itemAttr = []) {
      let qty = 0;
      for(let index = 0; index < itemAttr.length; index++) {
        qty = qty + (+itemAttr[index].quantity);
      }
      return qty;
    },
    showEvent(item) {
      if (item.soldQuantity > 0) {
        this.showAddonSales = true;
        this.selectedAddon = item;
      }
    }
  },
   watch: {
    'query.name': function() {
      clearTimeout(this.debounce);
      this.debounce = setTimeout(async () => {
        if (this.query.name && this.query.name.length > 2) {
          this.query.page = 1;
          this.get();
        }
        if (!this.query.name) {
          this.query.page = 1;
          this.get();
        }
      }, 600);
    },
    'query.price': function() {
      clearTimeout(this.debounce);
      this.debounce = setTimeout(async () => {
        if (this.query.price && this.query.price.length > 0) {
          this.query.page = 1;
          this.get();
        }
        if (!this.query.price) {
          this.query.page = 1;
          this.get();
        }
      }, 600);
    },
    'query.quantity': function() {
      clearTimeout(this.debounce);
      this.debounce = setTimeout(async () => {
        if (this.query.quantity && this.query.quantity.length > 0) {
          this.query.page = 1;
          this.get();
        }
        if (!this.query.quantity) {
          this.query.page = 1;
          this.get();
        }
      }, 600);
    },
    'query.supplier': function() {
      clearTimeout(this.debounce);
      this.debounce = setTimeout(async () => {
        if (this.query.supplier && this.query.supplier.length > 2) {
          this.query.page = 1;
          this.get();
        }
        if (!this.query.supplier) {
          this.query.page = 1;
          this.get();
        }
      }, 600);
    },
    'query.type': function() {
      clearTimeout(this.debounce);
      this.debounce = setTimeout(async () => {
        if (this.query.type && this.query.type.length > 2) {
          this.query.page = 1;
          this.get();
        }
        if (!this.query.type) {
          this.query.page = 1;
          this.get();
        }
      }, 600);
    },
    'query.page': function() {
      this.get();
    },
   }
};
</script>
